import Vue from 'vue';
import ExternalCountdown from './index.vue';
// import Test from './test.vue';

export function initExternalCountdown () {
  const countdown = document.querySelectorAll('.fscom-external-countdown');
  if (countdown.length <= 0) {
    return;
  }
  countdown.forEach((c) => {
    if (c) {
      const isRightRail = c.getAttribute('data-isRightRail');
      const startDate = c.getAttribute('data-startdate');
      // eslint-disable-next-line no-new
      new Vue({
        el: c,
        render: h => h(ExternalCountdown, {
          props: {
            model: {
              date: startDate,
              webUrl: 'https://www.foxsports.com/nfl/super-bowl',
              rightRail: isRightRail === 'true',
              countdownText: 'COUNTDOWN TO SB LIX KICKOFF',
              countdownLinkText: 'View Latest Super Bowl Updates',
              rightRailImage: 'https://b.fssta.com/uploads/application/superbowl/SB-LIX-Cleatus.vresize.300.168.medium.0.jpg',
              backgroundImage: 'https://b.fssta.com/uploads/application/superbowl/SB-LIX-Cleatus.vresize.867.488.medium.0.jpg',
              logo: 'https://b.fssta.com/uploads/application/nfl/logos/SuperBowlLIX.vresize.120.120.medium.0.png'
            }
          }
        })
      });
    }
  });
}
