var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fscom-external-countdown-container",
      class: { "right-rail": _vm.model.rightRail },
    },
    [
      _c("div", { staticClass: "cd-wrapper" }, [
        _c("div", { staticClass: "cd-image" }, [
          _c("div", { staticClass: "overlay" }),
          _vm._v(" "),
          _c("img", { attrs: { src: _vm.background } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cont" }, [
          !_vm.model.rightRail
            ? _c("img", {
                attrs: { src: _vm.model.logo, width: "120", height: "120" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "cd-text" }, [
            _vm._v(" " + _vm._s(_vm.model.countdownText) + " "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cd-display" },
            _vm._l(_vm.countdownData, function (data) {
              return _c(
                "div",
                { key: data.label, staticClass: "countdown-time-display" },
                [
                  _c("div", { staticClass: "time-value" }, [
                    _vm._v(
                      "\n            " + _vm._s(data.time) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "time-label" }, [
                    _vm._v(
                      "\n            " + _vm._s(data.label) + "\n          "
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "a",
            { staticClass: "cd-link", attrs: { href: _vm.model.webUrl } },
            [_vm._v(" " + _vm._s(_vm.model.countdownLinkText) + " ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }